import React, { useContext } from "react";

import { FaWhatsapp } from "react-icons/fa6";
import { WebsiteContext } from "../WebsiteContext";

export default function WhoWeAre() {
  const { websiteData } = useContext(WebsiteContext);
  return (
    <div className="container relative mt-8">
      <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
        <div className="lg:col-span-5 md:col-span-6">
          <div className="grid grid-cols-12 gap-6 items-center">
            <div className="col-span-6">
              <div className="grid grid-cols-1 gap-6">
                <img
                  src={websiteData?.about_im_1}
                  className="shadow rounded-md"
                  alt=""
                />
                <img
                  src={websiteData?.about_im_2}
                  className="shadow rounded-md"
                  alt=""
                />
              </div>
            </div>

            <div className="col-span-6">
              <div className="grid grid-cols-1 gap-6">
                <img
                  src={websiteData?.about_im_3}
                  className="shadow rounded-md"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:col-span-7 md:col-span-6">
          <div className="lg:ms-5">
            <div className="flex mb-4">
              {/* <span className="text-indigo-600 text-2xl font-bold mb-0"><CountUp className="counter-value text-6xl font-bold" start={1} end={20}></CountUp>+</span>
                            <span className="self-end  text-lg">سنة <br /> من الخبرة</span> */}
            </div>

            <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-indigo-700">
              {websiteData?.whoWeAre.title}
            </h3>

            <p className="text-black max-w-xl">
              {websiteData?.whoWeAre.content}
            </p>
            <h4 className="text-3xl mt-6 text-indigo-700 font-semibold">
              Send us a WhatsApp message
            </h4>

            <div className="mt-6">
              <a
                href={`https://wa.me/${websiteData?.whoWeAre.whatsappNumber}`}
                target="_blank"
                className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-green-500 hover:bg-green-600 border-green-500 hover:border-green-600 text-white rounded-md me-2 mt-2"
              >
                <FaWhatsapp className="me-2 text-sm" /> WhatsApp
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../component/Navbar/navbar";
import WhoWeAre from "../../component/whoWeAre";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import * as Icon from "react-feather";
import { about } from "../../data/dataTwo";
import FooterTwo from "../../component/Footer/footerTwo";
import cover from "../../assets/images/cover.jpg";
import { WebsiteContext } from "../../WebsiteContext";
export default function IndexItSolution() {
  const { websiteData, loading, error } = useContext(WebsiteContext); // Access the website data from context

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="loader">Loading...</div> {/* Add a loading spinner */}
      </div>
    );
  }
  return (
    <div className="font-cairo">
      <Navbar navClass="nav-light" />

      <section
        className="relative table w-full py-36 pb-0 lg:py-44 lg:pb-0 bg-indigo-600 bg-center bg-no-repeat bg-cover lg:h-[500px]"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${websiteData?.titleImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container relative">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
            <div className="lg:col-span-7">
              <div className="md:me-6 md:mb-20">
                <h4 className="font-semibold lg:leading-normal leading-normal text-3xl lg:text-5xl mb-5 text-white">
                  {websiteData?.title}
                </h4>
                <p className="text-white text-lg max-w-2xl font-semibold">
                  {websiteData?.description}
                </p>
              </div>
            </div>

            <div className="lg:col-span-5"></div>
          </div>
        </div>
      </section>
      <div id="about"></div>
      <WhoWeAre />
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-gray-50 dark:text-slate-800">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <div id="services"></div>
      <section className="relative md:py-24 py-16" id="services">
        <div className="container relative md:mb-24 mb-16">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-indigo-900">
              {websiteData?.serviceTitle}
            </h3>
            <p className="text-slate-400 max-w-xl mx-auto">
              {websiteData?.serviceDescription}
            </p>
          </div>

          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {websiteData?.services.map((data, index) => {
              return (
                <div
                  key={index}
                  className={`group relative p-6 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 hover:bg-indigo-600 dark:hover:bg-indigo-600 transition-all duration-500 ease-in-out rounded-xl bg-white dark:bg-slate-900 overflow-hidden text-center ${
                    index === 4 ? "lg:col-span-2 md:col-span-2" : ""
                  }`}
                >
                  <div className="relative overflow-hidden text-transparent -m-3">
                    <Icon.Hexagon className="size-24 fill-indigo-600/5 group-hover:fill-white/10 mx-auto"></Icon.Hexagon>
                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-indigo-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <img src={data.image} className="size-14" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <Link
                      to="#"
                      className="text-lg font-bold group-hover:text-white transition-all duration-500 ease-in-out text-indigo-900"
                    >
                      {data.title}
                    </Link>
                    <p className="text-slate-400 group-hover:text-white/50 transition-all duration-500 ease-in-out mt-3">
                      {data.feature}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <div id="contact"></div>
      <FooterTwo />
    </div>
  );
}

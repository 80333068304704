import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

// Create the context
export const WebsiteContext = createContext();

// Context provider component
export const WebsiteProvider = ({ API, children }) => {
  const [websiteData, setWebsiteData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch website data when the component mounts
  useEffect(() => {
    const fetchWebsiteData = async () => {
      try {
        const response = await axios.get(`${API}/api/websites/malak`);
        setWebsiteData(response.data);
      } catch (err) {
        setError("Failed to fetch website data.");
        console.error("Error fetching website data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchWebsiteData();
  }, [API]);

  return (
    <WebsiteContext.Provider value={{ websiteData, loading, error }}>
      {children}
    </WebsiteContext.Provider>
  );
};

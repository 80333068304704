import React, { useContext } from "react";
import logo from "../../assets/images/malakLogo.png";
import MailIcon from "../../assets/images/mail.png";
import { WebsiteContext } from "../../WebsiteContext";
export default function FooterTwo() {
  const { websiteData } = useContext(WebsiteContext);

  return (
    <footer
      className="footer relative text-gray-200 dark:text-gray-200"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${websiteData?.footerCover})`,
        backgroundSize: "cover",
      }}
    >
      <div className="container relative">
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className="py-[60px] px-0">
              <div className="grid grid-cols-1">
                <div className="text-center">
                  <img
                    src={logo}
                    className="block mx-auto"
                    alt=""
                    style={{ height: "90px", width: "90px" }}
                  />
                  <p className="max-w-xl mx-auto mt-8 text-2xl font-bold">
                    Contact Us
                  </p>
                </div>
                <div className="list-none text-center mt-8 grid justify-center items-center gap-[25px]">
                  <div className="flex items-center justify-center gap-2">
                    <a
                      href={`mailto:${websiteData?.email}`}
                      target="_blank"
                      className="h-6 w-6 text-white"
                    >
                      <img src={MailIcon} />
                    </a>
                    <p className="font-semibold">Email: {websiteData?.email}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
